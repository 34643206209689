<template>
    <div class="profile ">
        <Logo />
        <div class="logo-icon">
            <img src="@/assets/imgs/logo_icon.png" alt="avatar">
        </div>
        <div class="color-white node_name">
            {{ $t('profile.nodesText')[userinfo.node_level] }}
        </div>
        <div class="card">
            <div class="id-tag">ID: {{ userinfo.id }}</div>
            <img class="bg" src="/statics/imgs/cards/focard.png" alt="">
            <div class="address">
                {{userinfo.address}}
            </div>
            <div class="overdue">
                {{expiredDay}}
            </div>            
        </div>
        <a-row :gutter="16" class="nav-bars">
            <a-col :span="8">
                <div class="box nav-bar invite" @click="invite">
                    <div class="icon">
                        <a-icon type="user-add" />
                    </div>
                    <div class="title">
                        {{$t('profile.titles.invite')}}
                    </div>
                </div>
            </a-col>
            <a-col :span="8">
                <div class="box nav-bar" @click="$router.push({name:'performances'})">
                    <div class="icon">
                        <a-icon type="heat-map" />
                    </div>
                    <div class="title">
                        {{$t('profile.titles.performance')}}
                    </div>
                </div>
            </a-col>
            <a-col :span="8">
                <div class="box nav-bar" @click="$router.push({name:'security'})">
                    <div class="icon">
                        <a-icon type="safety-certificate" />
                    </div>
                    <div class="title">
                        {{$t('profile.titles.accountSecurity')}}
                    </div>
                </div>
            </a-col>
            <a-col :span="8">
                <div class="box flex nav-bar language" @click="changeLang">
                    <div class="icon">
                        <a-icon type="global" />
                    </div>
                    <div class="title">
                        {{$t('profile.titles.language')}}
                    </div>
                </div>
            </a-col>
            <a-col :span="8">
                <div class="box flex nav-bar about-us" @click="$router.push({name:'article',params:{id:1}})">
                <div class="icon">
                    <a-icon type="tag" />
                </div>
                <div class="title">
                    {{$t('profile.titles.aboutUs')}}
                </div>
            </div>
            </a-col>
            <a-col :span="8">
                <div class="box flex nav-bar logout" @click="logout">
                <div class="icon">
                    <a-icon type="logout" />
                </div>
                <div class="title">{{$t('profile.titles.logout')}}</div>
            </div>
            </a-col>
        </a-row>
        <div v-if="userinfo.address == 'TKMmXkaNQHVCy7kfFGhApSqbna2ypqyPsS'" class="color-white" @click="$router.push({name:'manage'})">
            <a-icon type="audit" />
            合约管理
        </div>
        <Nav />
    </div>
</template>
<script>
// import config from "../config";
import { formatDate } from "../utils/funs/date";
import Logo from "../components/Logo.vue";
import Nav from "../components/Nav.vue";

export default {
    name: "Profile",
    data() {
        return {
            userinfo: this.$store.state.userinfo
        };
    },
    components: {
        Logo, Nav
    },
    computed: {
        expiredDay(){
            let d = new Date(this.userinfo.created_at);
            d.setFullYear(d.getFullYear()+5);
            return formatDate(d,'M/yy');
        }
    },
    mounted() {
        this.getUserinfo();
    },
    methods: {
        commingSoon(){
            this.$message.info(this.$t('common.commingsoon'));
        },
        logout(){
            let _this = this
            this.$http.call(this.$http.api.logout).then(function(res){
                console.log(res)
                
            },function(res){
                console.log('something goes wrong...');
                console.log(res);
            }).then(()=>{
                _this.$store.commit("setToken","");
                _this.$store.commit("setUserinfo",{
                    address: '', balance: 0.00, node_level: 0
                });
                _this.$router.push({name:'login'});
            });
            
        },
        getUserinfo() {
            let _this = this
            this.$http.call(this.$http.api.userinfo).then(res=>{
                console.log(res);
                _this.userinfo = res.data.data;
                _this.$store.commit('setUserinfo', res.data.data);

            },res=>{
                // console.log('something goes wrong...');
                console.log(res);
                if(res.response.status == '401'){
                    this.$store.commit('setToken','');
                    this.$router.push({name:'login'});
                }
            }).then(()=>{
                
            });
        },
        
        // copy(data){
        //     let oInput = document.createElement('input');
        //     oInput.value = data;
        //     document.body.appendChild(oInput);
        //     oInput.select(); // 选择对象;
        //     console.log(oInput.value)
        //     document.execCommand("Copy"); // 执行浏览器复制命令
        //     this.$message.success(this.$t('common.copySuccess'));
        //     oInput.remove();
        // },
        // formatAddress(){
        //     return this.$store.state.userinfo.address.substring(0,16)+'...'+
        //     this.$store.state.userinfo.address.substring(27,34)
        // },
        invite(){
            this.$store.commit('setBtmBox',true);
            this.$store.commit('setShowInviteBox',true);
        },
        changeLang(){
            this.$store.commit('setBtmBox',true);
            this.$store.commit('setShowLangBox',true);
        }
    },
};
</script>
<style lang="less" scoped>
.profile {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 0 50px;
    padding-bottom: 168px;
    .logo-icon {
        width: 200px;
        margin: 0 auto;
        // background: #ffffff;
        background: radial-gradient(ellipse at center,  #fceabb 0%,#fccd4d 37%,#fccd4d 37%,#fccd4d 43%,#f8b500 60%,#ffffff 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */

        border-radius: 50%;
        img {
            width: 100%;
        }
    }
    .card{
        position: relative;
        font-size: 14PX;
        color: #eee;
        text-shadow: 1px 1px 1px #666, -1px 0px 1px #fff;
        margin-left: -50px;
        margin-right: -50px;
        .bg{
            width: 100%;
        }
        .id-tag {
            position: absolute;
            top: 100px;
            left: 100px;
            z-index: 1;
        }
        .address{
            position: absolute;
            margin: 0 auto;
            bottom: 256px;
            right: 100px;
            z-index: 1;
            font-size: 38px;
        }
        .overdue{
            position: absolute;
            bottom: 90px;
            left: 280px;
            z-index: 1;
        }
    }
    .nav-bars{
        display: flex;
        flex-wrap: wrap;
        // margin: 0 -50px;
        .nav-bar{
            // background: #dadada;
            color: @primary-color;
            padding: 50px;
            margin: 50px auto;
            // width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .icon {
                font-size: 24PX;
                padding-bottom: 12px;
                opacity: .8;
            }
        }
    }
    
}

</style>
